import React from 'react';
import styled from '@emotion/styled';

const StyledSecondServiceHeroDescription = styled.div`
    
`;

const SecondServiceHeroDescription = () => {
  
    <StyledSecondServiceHeroDescription>
        <p>
            
        </p>
    </StyledSecondServiceHeroDescription>
    
  }
  
  export default SecondServiceHeroDescription;